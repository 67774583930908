import { Avatar, Box, Button, Chip, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { lightBackground1, lightBlack } from '../../mainTheme';
import { useHistory, useParams } from 'react-router-dom';
import { faBarsFilter } from '@fortawesome/pro-light-svg-icons';
import DataGridTable from '../Shared/DataGridTable/DataGridTable';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomer } from '../../store/customers/actionCreators';
import { RootState } from '../../store';
import { isNull } from 'lodash';
import LoadingSpinner from '../Shared/LoadingSpinner';
import {
  formatDate,
  getCareTeamRoleById,
  getCareTeamRoleColorById,
} from '../../shared/utils';
import { editOwner } from '../../store/hubOwner/actionCreators';
import * as Yup from 'yup';
import { Field, Form, Formik, FormikProps } from 'formik';
import MaskedInput from '../Shared/MaskedInput';
import { TextInputFormField } from '../Shared/TextInputFormField';
import ActionCell from '../Shared/ActionCell';
import { CARETEAM_ROLE } from '../../shared/constants';

const baseStyle = {
  color: '#000',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '24px',
  letterSpacing: '0.2px',
};

const headingStyle = {
  ...baseStyle,
  fontSize: '22px',
  lineHeight: '30px',
  letterSpacing: '0.36px',
};

const headLineStyle = {
  ...baseStyle,
  fontSize: '18px',
};

const contentStyle = {
  ...baseStyle,
  color: 'var(--Font-F3---Body, #585863)',
  fontSize: '16px',
  paddingBottom: 2,
};

const stateSelector = (state: RootState) => {
  const { customers } = state;

  const { careTeam, isLoading, facilityCustomers } = customers;
  return {
    careTeam,
    isLoading,
    facilityCustomers,
  };
};

const validationSchema = Yup.object().shape({
  phone: Yup.string()
    .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Phone number is not valid')
    .required('Phone number is required'),
  address1: Yup.string().required('Address is required'),
});

const HubOwnerProfile = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const { careTeam, isLoading, facilityCustomers } = useSelector<
    RootState,
    ReturnType<typeof stateSelector>
  >(stateSelector);

  const { isLoading: isUpdatingOwner } = useSelector(
    (state: RootState) => state.hubOwner
  );

  const [isEditing, setIsEditing] = useState(false);
  const formikRef =
    useRef<FormikProps<{ phone: string; address1: string }>>(null);

  const handleBackClick = () => {
    history.goBack();
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleActionNavigation = (
    careTeamRoleId: number,
    customerId: number
  ) => {
    if (
      careTeamRoleId === CARETEAM_ROLE.PROFESSIONAL_CAREGIVER ||
      careTeamRoleId === CARETEAM_ROLE.FACILITY_SCHEDULER
    ) {
      history.push(`/v2/caregivers/${customerId}`);
    }
  };

  const handleSaveClick = async () => {
    const values = formikRef?.current?.values;
    // Await to call the user updated details when this completes before moving on
    await dispatch(
      editOwner(Number(id), {
        phone: values?.phone || '',
        address1: values?.address1 || '',
      })
    );
    // Dispatch getCustomer after editOwner completes
    await dispatch(getCustomer(Number(id)));
    setIsEditing(false);
  };

  const ownerDetails = (facilityCustomers || []).find(
    (customer) => customer.customer_id === Number(id)
  );

  const columns = [
    { field: 'careTeam', headerName: 'Care Team', flex: 1 },
    {
      field: 'role',
      headerName: 'Role',
      flex: 1,
      renderCell: (params: any) => (
        <Box display="flex" alignItems="center">
          <Chip
            avatar={
              <Avatar
                sx={{
                  bgcolor: 'white',
                  border: `2px solid ${params.value.borderColor}`,
                }}
              >
                <Typography
                  variant="caption"
                  sx={{
                    color: params.value.borderColor,
                  }}
                >
                  {params.value.text.charAt(0)}
                </Typography>
              </Avatar>
            }
            label={
              <Typography sx={{ color: lightBlack }} variant="caption">
                {params.value.text}
              </Typography>
            }
            sx={{
              backgroundColor: params.value.color,
              color: 'white',
              '& .MuiChip-icon': {
                color: 'white',
              },
            }}
          />
        </Box>
      ),
    },
    { field: 'dateAdded', headerName: 'Date added', flex: 1 },
    {
      field: 'action',
      headerName: 'Action',
      renderCell: (params: any) => (
        <ActionCell
          handleNavigation={() =>
            handleActionNavigation(params.row.careTeamRoleId, params.row.id)
          }
        />
      ),
    },
  ];

  const rows =
    careTeam && careTeam[id]
      ? careTeam[id].map((owner) => ({
          id: owner.customer_id,
          careTeam: `${owner.first_name} ${owner.last_name}`,
          careTeamRoleId: owner.careteam_role_id,
          role: {
            color: getCareTeamRoleColorById(owner.careteam_role_id)
              .backgroundColor,
            borderColor: getCareTeamRoleColorById(owner.careteam_role_id)
              .borderColor,
            text: getCareTeamRoleById(owner.careteam_role_id),
          },
          dateAdded: owner.created_at
            ? formatDate(owner.created_at)
            : 'No data',
          action: faBarsFilter,
        }))
      : [];

  useEffect(() => {
    if (!ownerDetails && !isLoading) {
      dispatch(getCustomer(Number(id)));
    }
  }, [dispatch, id, isLoading, ownerDetails]);

  if (!ownerDetails || isNull(careTeam) || isLoading || isUpdatingOwner) {
    return <LoadingSpinner />;
  }

  return (
    <Box display="flex" flexDirection="column" bgcolor={lightBackground1} p={2}>
      <Box>
        <Button
          variant="text"
          color="secondary"
          onClick={handleBackClick}
          data-testid="back-button"
        >
          Back
        </Button>
      </Box>
      <Box py={2}>
        <Typography sx={headingStyle}>{ownerDetails?.name}</Typography>
        <Box>
          <Chip label="Hub Owner" />
        </Box>
      </Box>
      <Box>
        <Typography sx={headLineStyle} py={2}>
          Schedule
        </Typography>
        <Box display="flex" alignItems="baseline" pb={2}>
          <Typography sx={headLineStyle}>Contact details</Typography>
          <Button
            type="submit"
            variant="text"
            color="secondary"
            onClick={isEditing ? handleSaveClick : handleEditClick}
          >
            {isEditing ? 'Save' : 'Edit'}
          </Button>
          {isEditing && (
            <Button
              variant="text"
              color="secondary"
              onClick={() => {
                setIsEditing(false);
              }}
            >
              Cancel
            </Button>
          )}
        </Box>

        <Typography sx={headLineStyle}>Email</Typography>
        <Typography sx={contentStyle}>{ownerDetails?.email}</Typography>

        <Typography sx={headLineStyle}>Phone</Typography>
        {isEditing ? (
          <Formik
            innerRef={formikRef}
            initialValues={{
              phone: ownerDetails.phone || '',
              address1: ownerDetails.address1 || '',
            }}
            validationSchema={validationSchema}
            onSubmit={() => {}}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Box pb={1} sx={{ width: '50%' }}>
                  <Field
                    name="phone"
                    format="(###) ###-####"
                    placeholder="(123)-456-7890"
                    component={MaskedInput}
                    variant="standard"
                    size="small"
                    sx={{
                      fontSize: 18,
                      fontWeight: 'bold',
                      mb: 1,
                      color: 'red',
                    }}
                  />
                </Box>

                <Typography sx={headLineStyle}>Address</Typography>
                <Box pb={1} sx={{ width: '50%' }}>
                  <Field
                    name="address1"
                    placeholder="1234 Main Street"
                    component={TextInputFormField}
                    variant="standard"
                    size="small"
                  />
                </Box>
              </Form>
            )}
          </Formik>
        ) : (
          <>
            <Typography sx={contentStyle}>
              {ownerDetails?.phone || 'No data'}
            </Typography>
            <Typography sx={headLineStyle}>Address</Typography>
            <Typography sx={contentStyle}>
              {ownerDetails?.address1 || 'No data'}
            </Typography>
          </>
        )}

        <Typography sx={headLineStyle}>Hub activation date</Typography>
        <Typography sx={contentStyle}>
          {ownerDetails?.device_synced_at || 'No data'}
        </Typography>
      </Box>
      <DataGridTable
        rows={rows}
        columns={columns}
        pageSize={5}
        maxHeight="40vh"
      />
    </Box>
  );
};

export default HubOwnerProfile;
