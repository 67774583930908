import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useMemo } from 'react';
import * as yup from 'yup';
import { TextInputFormField } from '../../Shared/TextInputFormField';
import SelectFormField from '../../Shared/SelectFormField';
import { Box } from '@mui/material';

const schema = yup.object({
  owner_name: yup.string().trim(),
  med_status: yup.string(),
});

interface HubOwnersFilterFormProps {
  formRef: React.RefObject<
    FormikProps<{ owner_name: string; med_status: string; facility: string }>
  >;
  onSubmit: (values: any) => void;
  initialValues: { owner_name: string; med_status: string; facility: string };
}

const HubOwnersFilterForm: React.FC<HubOwnersFilterFormProps> = ({
  formRef,
  onSubmit,
  initialValues,
}) => {
  const medStatus = useMemo(() => {
    return [
      { label: 'Up to date', value: 'Up to date' },
      { label: 'Missed', value: 'Missed' },
    ];
  }, []);

  return (
    <Box>
      <Formik
        innerRef={formRef}
        validationSchema={schema}
        onSubmit={onSubmit}
        initialValues={initialValues}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Box display="flex" flexDirection="column" gap={2}>
              <Field
                label="Hub owner name"
                name="owner_name"
                placeholder="First or Last name"
                component={TextInputFormField}
              />
              <Field
                label="Med Status"
                name="med_status"
                placeholder="Select Med Status"
                component={SelectFormField}
                options={medStatus}
              />
              <Field
                label="Facility "
                name="facility"
                placeholder="Facility"
                component={TextInputFormField}
              />
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default HubOwnersFilterForm;
