import React from 'react';
import { Chip, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lightBlack } from '../../mainTheme';

interface StatusChipProps {
  icon: any;
  iconColor: string;
  labelText: string;
  conditionalValue: boolean;
  backgroundColor?: string;
}

const StatusChip: React.FC<StatusChipProps> = ({
  icon,
  iconColor,
  labelText,
  conditionalValue,
  backgroundColor,
}) => {
  return (
    <Chip
      data-testid="status-chip"
      icon={
        <FontAwesomeIcon
          data-testid="status-chip-icon"
          icon={icon}
          style={{ color: iconColor }}
        />
      }
      label={
        <Typography
          data-testid="status-chip-label"
          sx={{ color: lightBlack }}
          variant="caption"
        >
          {labelText}
        </Typography>
      }
      sx={{
        backgroundColor: conditionalValue
          ? backgroundColor ?? 'var(--Semantics-S2---Success-Bg, #ECF8F0)'
          : 'var(--Semantics-S11---Error-Bg, #FCF0EE)',
        color: 'white',
        '& .MuiChip-icon': {
          color: 'white',
        },
      }}
    />
  );
};

export default StatusChip;
