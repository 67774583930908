import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Typography, TextField } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {
  TextInputFormField,
  IconAdornment,
} from '../../Shared/TextInputFormField';
import { faEye, faEyeSlash } from '@fortawesome/pro-light-svg-icons';
import { Link } from 'react-router-dom';
import { ReactComponent as WelcomeBackIcon } from '../../../images/icons/welcome_back_icon.svg';
import PasswordStrengthChecker from './PasswordStrengthChecker/PasswordStrengthChecker';
import Modal from '../../Shared/Modal';
import { AUTH_MESSAGES } from '../../../shared/constants';
import { RootState } from '../../../store';
import { AuthStoreActionTypes } from '../../../store/auth/authTypes';
import {
  login,
  emailVerificationRequest,
} from '../../../store/auth/actionCreators';
import MfaVerification from './MfaVerification';
import ReCaptchaVerifierV2 from '../../Shared/ReCaptchaVerifierV2/ReCaptchaVerifierV2';

const LoginFormCard = () => {
  const siteKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY || '';

  const dispatch = useDispatch();
  const validationMessage = useSelector(
    (state: RootState) => state.auth.validationMessage
  );
  const sessionToken = useSelector((state: RootState) => state.auth);

  const [email, setEmail] = useState('');
  const [showNewPassReq, setShowNewPassReq] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [showEmailVerReqModal, setShowEmailVerReqModal] = useState(false);

  const [showMfaVerification, setShowMfaVerification] = useState(false);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(!siteKey);

  useEffect(() => {
    if (
      validationMessage &&
      validationMessage.type === AuthStoreActionTypes.LOGIN_FAILURE &&
      validationMessage.message === AUTH_MESSAGES.EMAIL_VERIFICATION_REQUIRED
    ) {
      setShowEmailVerReqModal(true);
    } else if (
      validationMessage &&
      validationMessage.type === AuthStoreActionTypes.LOGIN_FAILURE &&
      validationMessage.message === AUTH_MESSAGES.NEW_PASSWORD_REQUIRED
    ) {
      setShowNewPassReq(true);
    } else if (
      validationMessage &&
      validationMessage.type === AuthStoreActionTypes.MFA_REQUIRED &&
      validationMessage.message === AUTH_MESSAGES.MFA_REQUIRED
    ) {
      setShowMfaVerification(true);
    }
  }, [validationMessage]);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const handleCloseEmailVerReqModal = () => {
    setShowEmailVerReqModal(false);
  };

  const handleCaptchaVerification = (verified: boolean) => {
    setIsCaptchaVerified(verified);
  };

  const loginYupObject = {
    email: Yup.string().email('Invalid email address').required('Required'),
    password: Yup.string().required('Required'),
  };
  const loginSchema = Yup.object(loginYupObject);
  const newPasswordSchema = Yup.object({
    ...loginYupObject,
    newPassword: Yup.string().when('password', {
      is: (val: string) => val?.length > 0 && showNewPassReq,
      then: Yup.string()
        .min(8, 'Minimum 8 characters')
        .matches(/[a-z]/, 'Contains lowercase letter')
        .matches(/[A-Z]/, 'Contains uppercase letter')
        .matches(/\d/, 'Contains number')
        .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Contains special character')
        .required('New password is required'),
    }),
    confirmPassword: Yup.string().when('newPassword', {
      is: (val: string) => val?.length > 0,
      then: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
        .required('Confirm new password is required'),
    }),
  });

  const verifyMfaCode = (mfaCode: string) => {
    if (mfaCode) {
      dispatch(
        login(
          email,
          undefined,
          undefined,
          mfaCode,
          sessionToken.sessionToken || ''
        )
      );
    }
  };

  const resendCode = () => {
    setShowMfaVerification(false);
  };

  return (
    <Box display="flex" flexDirection="column" gap={2} px={16}>
      {!showMfaVerification ? (
        <>
          <WelcomeBackIcon />
          <Typography gutterBottom>
            {showNewPassReq ? 'New Password Required' : 'Welcome back!'}
          </Typography>
          <Formik
            initialValues={{
              email: '',
              password: '',
              newPassword: '',
              confirmPassword: '',
            }}
            validationSchema={showNewPassReq ? newPasswordSchema : loginSchema}
            onSubmit={(values) => {
              const newPassword = showNewPassReq
                ? values.newPassword
                : undefined;
              dispatch(login(values.email, values.password, newPassword));
              setEmail(values.email);
            }}
          >
            {({ handleSubmit, values, isValid }) => (
              <Form onSubmit={handleSubmit}>
                <Field
                  name="email"
                  type="email"
                  label="Email"
                  component={TextInputFormField}
                  placeholder="Enter your email address"
                />
                <Field
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  label="Password"
                  placeholder="Enter your password"
                  component={TextInputFormField}
                  margin="normal"
                  InputProps={{
                    endAdornment: (
                      <IconAdornment
                        onClick={handleClickShowPassword}
                        faIconProps={{
                          icon: showPassword ? faEyeSlash : faEye,
                        }}
                      />
                    ),
                  }}
                />
                {showNewPassReq && (
                  <>
                    <Field name="newPassword">
                      {({
                        field,
                      }: {
                        field: {
                          name: string;
                          value: any;
                          onChange: (e: React.ChangeEvent<any>) => void;
                          onBlur: (e: React.FocusEvent<any>) => void;
                        };
                      }) => (
                        <TextField
                          {...field}
                          type={showNewPassword ? 'text' : 'password'}
                          label="New Password"
                          placeholder="Enter new password"
                          fullWidth
                          variant="outlined"
                          margin="normal"
                          InputProps={{
                            endAdornment: (
                              <IconAdornment
                                onClick={handleClickShowNewPassword}
                                faIconProps={{
                                  icon: showNewPassword ? faEyeSlash : faEye,
                                }}
                              />
                            ),
                          }}
                        />
                      )}
                    </Field>
                    {values.newPassword && (
                      <PasswordStrengthChecker password={values.newPassword} />
                    )}
                    <Field name="confirmPassword">
                      {({
                        field,
                        meta,
                      }: {
                        field: any;
                        meta: { touched: boolean; error?: string };
                      }) => (
                        <TextField
                          {...field}
                          type={showConfirmPassword ? 'text' : 'password'}
                          label="Confirm New Password"
                          placeholder="Confirm new password"
                          fullWidth
                          margin="normal"
                          error={meta.touched && Boolean(meta.error)}
                          helperText={meta.touched && meta.error}
                          InputProps={{
                            endAdornment: (
                              <IconAdornment
                                onClick={handleClickShowConfirmPassword}
                                faIconProps={{
                                  icon: showConfirmPassword
                                    ? faEyeSlash
                                    : faEye,
                                }}
                              />
                            ),
                          }}
                        />
                      )}
                    </Field>
                  </>
                )}
                {siteKey && (
                  <ReCaptchaVerifierV2
                    sitekey={siteKey}
                    onVerify={handleCaptchaVerification}
                  />
                )}
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  gap={4}
                  mt={4}
                >
                  <Link to="/auth/resetPassword">
                    <Typography variant="body2" color="secondary">
                      Forgot Password?
                    </Typography>
                  </Link>
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    color="secondary"
                    disabled={
                      !isCaptchaVerified ||
                      !isValid ||
                      (showNewPassReq &&
                        (!values.newPassword ||
                          !values.confirmPassword ||
                          values.newPassword !== values.confirmPassword))
                    }
                  >
                    {showNewPassReq ? 'Set New Password' : 'Login'}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </>
      ) : null}

      {showMfaVerification && (
        <MfaVerification
          verifyMfaCode={verifyMfaCode}
          resendCode={resendCode}
        />
      )}
      {showEmailVerReqModal && (
        <Modal
          title="Email Verification Required"
          showCloseX={true}
          onClose={handleCloseEmailVerReqModal}
          actions={{
            primaryAction: {
              text: 'Resend Email',
              onClick: () => {
                dispatch(emailVerificationRequest(email));
                handleCloseEmailVerReqModal();
              },
            },
            secondaryAction: {
              text: 'Cancel',
              onClick: handleCloseEmailVerReqModal,
            },
          }}
        >
          <p>
            Email verification required. An email should have previously been
            sent to you. Please check your email records for the link to
            complete the verification process. Or click the button below to
            resend the verification email.
          </p>
        </Modal>
      )}
    </Box>
  );
};

export default LoginFormCard;
