import {
  Avatar,
  Box,
  Button,
  Chip,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { lightBackground1, lightBlack } from '../../mainTheme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faBarsFilter,
  faPenToSquare,
} from '@fortawesome/pro-light-svg-icons';
import NewModal from '../Shared/NewModal';

import { FormikProps } from 'formik';
import { useHistory } from 'react-router-dom';
import DataGridTable from '../Shared/DataGridTable/DataGridTable';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import {
  getCareTeamRoleColorById,
  getFacilityRoleById,
} from '../../shared/utils';
import AddNewCaregiver, {
  NewCaregiverFormValues,
} from './components/AddNewCaregiver';
import CaregiverFilterForm from './components/CaregiverFilterForm';
import { DashboardUser } from '../../store/dashboardUser/dashboardUserTypes';
import { isNull } from 'lodash';
import { getDashboardUser } from '../../store/dashboardUser/actionCreators';
import {
  addCaregiver,
  getAllCaregivers,
  resetAddCaregiverSuccess,
} from '../../store/facility/actionCreators';
import { NewCaregiverData } from '../../store/facility/facilityTypes';
import LoadingSpinner from '../Shared/LoadingSpinner';
import { FACILITY_ROLE } from '../../shared/constants';

const ActionCell = ({ customerId }: { customerId: number }) => {
  const history = useHistory();
  const handleNavigation = () => {
    history.push(`/v2/caregivers/${customerId}`);
  };

  return (
    <Box display="flex" justifyContent="flex-end" width="100%">
      <IconButton size="small" onClick={handleNavigation}>
        <FontAwesomeIcon
          icon={faPenToSquare}
          style={{ marginRight: 16 }}
          size="sm"
        />
      </IconButton>
    </Box>
  );
};

const columns = [
  {
    field: 'caregiver',
    headerName: 'Caregiver',
    flex: 1,
    renderCell: (params: any) => (
      <Box display="flex" alignItems="center">
        <Typography variant="body2">{params.value}</Typography>
        {params.row.role.facilityRoleId === FACILITY_ROLE.FACILITY_ADMIN && (
          <Chip
            label={
              <Typography sx={{ color: lightBlack }} variant="caption">
                Admin
              </Typography>
            }
            sx={{
              marginLeft: 1,
              height: '24px',
              fontSize: '0.55rem',
              padding: '6px 12px',
            }}
          />
        )}
      </Box>
    ),
  },
  {
    field: 'role',
    headerName: 'Role',
    flex: 1,
    renderCell: (params: any) => (
      <Box display="flex" alignItems="center">
        <Chip
          avatar={
            <Avatar
              sx={{
                bgcolor: 'white',
                border: `2px solid ${params.value.borderColor}`,
              }}
            >
              <Typography
                variant="caption"
                sx={{
                  color: params.value.borderColor,
                }}
              >
                {params.value.text.charAt(0)}
              </Typography>
            </Avatar>
          }
          label={
            <Typography sx={{ color: lightBlack }} variant="caption">
              {params.value.text}
            </Typography>
          }
          sx={{
            backgroundColor: params.value.color,
            color: 'white',
            '& .MuiChip-icon': {
              color: 'white',
            },
          }}
        />
      </Box>
    ),
  },
  { field: 'lastLogin', headerName: 'Last login', flex: 1 },
  {
    field: 'action',
    headerName: 'Action',
    renderCell: (params: any) => <ActionCell customerId={params.row.id} />,
  },
];

const CaregiversNew = () => {
  const { dashboardUserInfo } = useSelector<
    RootState,
    {
      dashboardUserInfo: DashboardUser | null;
    }
  >((state) => {
    const { dashboardUser } = state;
    const { dashboardUserInfo } = dashboardUser;
    return {
      dashboardUserInfo,
    };
  });

  const dispatch = useDispatch();

  const { caregivers, isLoading, addCaregiverSuccess } = useSelector(
    (state: RootState) => state.facility
  );

  useEffect(() => {
    if (isNull(dashboardUserInfo)) {
      dispatch(getDashboardUser());
    }
  }, [dashboardUserInfo, dispatch]);

  useEffect(() => {
    if (dashboardUserInfo && dashboardUserInfo.facility_id) {
      dispatch(getAllCaregivers(dashboardUserInfo.facility_id, false));
    }
  }, [dashboardUserInfo, dispatch]);

  const [isAddCaregiverModalOpen, setIsAddCaregiverModalOpen] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({
    caregiver: '',
    role: '',
  });

  const rows = caregivers?.map((caregiver) => ({
    id: caregiver.customer_id,
    caregiver: `${caregiver.first_name} ${caregiver.last_name}`,
    role: {
      color: getCareTeamRoleColorById(caregiver.facility_role_id)
        .backgroundColor,
      borderColor: getCareTeamRoleColorById(caregiver.facility_role_id)
        .borderColor,
      text: getFacilityRoleById(caregiver.facility_role_id),
      facilityRoleId: caregiver.facility_role_id,
    },
    lastLogin: caregiver.last_login_at,
  }));

  const formikRef = useRef<FormikProps<NewCaregiverFormValues>>(null);
  const formRef =
    useRef<FormikProps<{ caregiver: string; role: string }>>(null);

  const handleContinue = () => {
    if (formikRef.current) {
      formikRef.current.submitForm();
    }
    if (formRef.current) {
      formRef.current.submitForm();
    }
  };

  const onSubmit = (values: NewCaregiverData) => {
    if (formikRef.current) {
      dispatch(addCaregiver(values));
    }
  };

  useEffect(() => {
    if (addCaregiverSuccess) {
      setIsAddCaregiverModalOpen(false);
      dispatch(resetAddCaregiverSuccess()); // Reset the success state
      if (dashboardUserInfo?.facility_id) {
        dispatch(getAllCaregivers(dashboardUserInfo?.facility_id, false));
      }
    }
  }, [addCaregiverSuccess, dashboardUserInfo?.facility_id, dispatch]);

  const onSubmitFilterForm = (values: any) => {
    if (formRef.current) {
      setFilters(values);
      setIsFilterModalOpen(false);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = rows?.filter((row) => {
    const matchesSearchTerm =
      row.caregiver.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.role.facilityRoleId.toString().includes(searchTerm.toLowerCase()) ||
      row.lastLogin.toLowerCase().includes(searchTerm.toLowerCase());

    const matchesFilters =
      (filters.caregiver === '' ||
        row.caregiver
          .toLowerCase()
          .includes(filters.caregiver.toLowerCase())) &&
      (filters.role === '' || row.role.facilityRoleId === Number(filters.role));

    return matchesSearchTerm && matchesFilters;
  });

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Box display="flex" flexDirection="column" bgcolor={lightBackground1}>
      <Box flexGrow={1} p={4}>
        <Box display="flex" justifyContent="space-between">
          <Typography>Caregivers</Typography>
          <Button
            type="submit"
            variant="outlined"
            color="secondary"
            startIcon={<FontAwesomeIcon icon={faPlus} />}
            onClick={() => setIsAddCaregiverModalOpen(true)}
          >
            Add new caregiver
          </Button>
        </Box>
        <Typography variant="caption">Search</Typography>
        <Box display="flex" gap={2}>
          <TextField
            placeholder="Search"
            value={searchTerm}
            onChange={handleSearchChange}
            fullWidth
            variant="outlined"
          />
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<FontAwesomeIcon icon={faBarsFilter} />}
            onClick={() => setIsFilterModalOpen(true)}
          />
        </Box>
        <DataGridTable
          rows={filteredData || []}
          columns={columns}
          loading={isLoading}
        />
      </Box>
      {isAddCaregiverModalOpen ? (
        <NewModal
          showCloseX
          title="Add new Caregiver"
          onClose={() => {
            setIsAddCaregiverModalOpen(false);
          }}
          actions={{
            primaryAction: { onClick: handleContinue },
          }}
          backdropBlur
          isLoading={isLoading}
        >
          <AddNewCaregiver formRef={formikRef} onSubmit={onSubmit} />
        </NewModal>
      ) : null}
      {isFilterModalOpen ? (
        <NewModal
          showCloseX
          title="Filter"
          onClose={() => {
            setIsFilterModalOpen(false);
            setFilters({
              caregiver: '',
              role: '',
            });
            if (formRef.current) {
              formRef.current.resetForm();
            }
          }}
          actions={{
            primaryAction: { onClick: handleContinue },
          }}
        >
          <CaregiverFilterForm
            formRef={formRef}
            onSubmit={onSubmitFilterForm}
            initialValues={filters}
          />
        </NewModal>
      ) : null}
    </Box>
  );
};

export default CaregiversNew;
