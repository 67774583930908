import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { ReactComponent as EmailIcon } from '../../../images/icons/email_icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  emailVerificationRequest,
  validateEmailRequest,
} from '../../../store/auth/actionCreators';
import { AuthStoreActionTypes } from '../../../store/auth/authTypes';
import { RootState } from '../../../store';
import WarningModal from './WarningModal';
import { AUTH_MESSAGES } from '../../../shared/constants';

const VerifyEmailCard = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const validationMessage = useSelector(
    (state: RootState) => state.auth.validationMessage
  );

  const queryParams = new URLSearchParams(location.search);
  const accessToken = queryParams.get('access_token');
  let email = queryParams.get('email');
  email = email ? email.replace(' ', '+') : email;

  const [alreadyVerifiedWarnModalOpen, setAlreadyVerifiedWarnModalOpen] =
    useState(false);
  const [isExpWarnModalOpen, setExpWarnModalOpen] = useState(false);
  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);

  useEffect(() => {
    if (!(accessToken && email)) {
      history.push('/auth/login');
    }
  }, [dispatch, accessToken, email, history]);

  useEffect(() => {
    if (
      validationMessage &&
      validationMessage.type === AuthStoreActionTypes.VALIDATE_EMAIL_REQUEST &&
      validationMessage.email === email &&
      validationMessage.message === AUTH_MESSAGES.EMAIL_ALREADY_VERIFIED
    ) {
      setAlreadyVerifiedWarnModalOpen(true);
    } else if (
      validationMessage &&
      validationMessage.type === AuthStoreActionTypes.VALIDATE_EMAIL_REQUEST &&
      validationMessage.email === email &&
      validationMessage.message === AUTH_MESSAGES.FAILED_TOKEN_VALIDATION
    ) {
      setExpWarnModalOpen(true);
    } else if (
      validationMessage &&
      validationMessage.type === AuthStoreActionTypes.VALIDATE_EMAIL_REQUEST &&
      validationMessage.email === email &&
      validationMessage.message === AUTH_MESSAGES.EMAIL_VERIFICATION_SUCCESS
    ) {
      setSuccessModalOpen(true);
    }
  }, [validationMessage, email]);

  const handleClick = async () => {
    if (!accessToken) {
      setExpWarnModalOpen(true);
    }
    if (accessToken && email) {
      dispatch(validateEmailRequest(accessToken, email));
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
      p={16}
      alignItems="center"
    >
      <EmailIcon />
      <Typography>Email Verification</Typography>
      <Button
        fullWidth
        variant="contained"
        color="secondary"
        onClick={handleClick}
      >
        Verify
      </Button>
      <WarningModal
        message="Your email has already been previously Verified."
        open={alreadyVerifiedWarnModalOpen}
        onClose={() => {
          setAlreadyVerifiedWarnModalOpen(false);
          history.push('/auth/login');
        }}
      />
      <WarningModal
        message="The link to verify your email has expired. Click the OK button to request a new link."
        open={isExpWarnModalOpen}
        onClose={() => {
          if (email) dispatch(emailVerificationRequest(email));
          setExpWarnModalOpen(false);
        }}
      />
      <WarningModal
        message={AUTH_MESSAGES.EMAIL_VERIFICATION_SUCCESS}
        open={isSuccessModalOpen}
        onClose={() => {
          setSuccessModalOpen(false);
          history.push('/auth/login');
        }}
      />
    </Box>
  );
};

export default VerifyEmailCard;
