import React, { useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import NewModal from '../../Shared/NewModal';
import { ReactComponent as DeleteIcon } from '../../../images/icons/delete_icon.svg';
import { dimGrey, lightBlack } from '../../../mainTheme';

interface DeleteUserModalProps {
  onClose: () => void;
  onDelete: () => void;
}

const DeleteUserModal: React.FC<DeleteUserModalProps> = ({
  onClose,
  onDelete,
}) => {
  const commonTypographyProps = {
    color: lightBlack,
  };

  const [confirmationText, setConfirmationText] = useState('');

  const handleDelete = () => {
    if (confirmationText === 'delete') {
      onDelete();
    }
  };

  return (
    <NewModal maxWidth="sm" onClose={onClose} title="Delete user" showCloseX>
      <Box>
        <DeleteIcon />
        <Box>
          <Typography variant="h6">Confirm deletion</Typography>
          <Typography sx={{ color: dimGrey }} component="span">
            Confirming means this user will be{' '}
            <Typography
              component="span"
              fontWeight="bold"
              {...commonTypographyProps}
            >
              permanently deleted.
            </Typography>
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          py={4}
          gap={0.5}
        >
          <Typography fontWeight="bold" {...commonTypographyProps}>
            Type the word &quot;delete&quot; to confirm deletion.
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            value={confirmationText}
            onChange={(e) => setConfirmationText(e.target.value)}
          />
        </Box>

        <Box width="100%" display="flex" justifyContent="center" gap={3} px={2}>
          <Button variant="text" color="secondary" fullWidth onClick={onClose}>
            Cancel deletion
          </Button>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={handleDelete}
            disabled={confirmationText !== 'delete'}
          >
            Delete User
          </Button>
        </Box>
      </Box>
    </NewModal>
  );
};

export default DeleteUserModal;
