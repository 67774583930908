import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import NewModal from '../../Shared/NewModal';
import { ReactComponent as DeletedIcon } from '../../../images/icons/deleted_icon.svg';

interface UserDeletedModalProps {
  onClose: () => void;
}

const UserDeletedModal: React.FC<UserDeletedModalProps> = ({ onClose }) => {
  return (
    <NewModal maxWidth="xs" onClose={onClose} title="Delete user" showCloseX>
      <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
        <DeletedIcon />
        <Typography variant="h6">User deleted</Typography>
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          onClick={onClose}
        >
          OK
        </Button>
      </Box>
    </NewModal>
  );
};

export default UserDeletedModal;
