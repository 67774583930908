import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React from 'react';
import {
  BrowserRouter,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
  Redirect,
} from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { restHost, storageHost, webSocketHost } from './apiConfig';
import Caregivers from './components/Customers/Caregivers';
import CareTeamDetails from './components/Customers/components/CareTeamDetails';
import CreatePrimaryContact from './components/Customers/components/CreatePrimaryContact';
import CustomerDetail from './components/Customers/components/CustomerDetail';
import MedicationEventHistory from './components/Customers/components/MedicationEventHistory';
import CreateNewFacilityUser from './components/Customers/CreateNewFacilityUser';
import CreateNewOwner from './components/Customers/CreateNewOwner';
import Customers from './components/Customers/Customers';
import Owners from './components/Customers/Owners';
import DeviceDetail from './components/Devices/DeviceDetail';
import Devices from './components/Devices/Devices';
import Operations from './components/Operations/Operations';
import AddNewFacility from './components/Facility/AddNewFacility';
import Facilities from './components/Facility/Facilities';
import FacilityDetail from './components/Facility/FacilityDetail';
import MyFacilityDetail from './components/Facility/MyFacilityDetail';
import MyAccount from './components/MyAccount/MyAccount';
import Pharmacies from './components/Pharmacy/Pharmacies';
import PharmacyDetail from './components/Pharmacy/PharmacyDetail';
import Register from './components/Register';
import Reporting from './components/Reporting/Reporting';
import CreateNewSoftwareVersion from './components/SoftwareVersions/CreateNewSoftwareVersion';
import EditSoftwareVersion from './components/SoftwareVersions/EditSoftwareVersion';
import SoftwareDeployment from './components/SoftwareVersions/SoftwareVersionDeployment';
import Software from './components/SoftwareVersions/SoftwareVersions';
import './css/App.css';
import './css/toast.css';
import { getCookie } from './shared/utils';
import 'react-toastify/dist/ReactToastify.css';
import NavBarIndex from './components/Dashboard/NavBarIndex';
import DashboardIndex from './components/Dashboard/DashboardIndex';
import Login from './components/Auth/Login';
import ResetPassword from './components/Auth/ResetPassword';
import { RouteProps } from 'react-router-dom';
import RegisterEmailVerify from './components/Auth/RegisterEmailVerify';
import HubOwners from './components/HubOwners/HubOwners';
import HubOwnerProfile from './components/HubOwners/HubOwnerProfile';
import CaregiversNew from './components/Caregivers/CaregiversNew';
import CaregiverProfile from './components/Caregivers/CaregiverProfile';
import HubIndex from './components/Hubs/HubIndex';
import HubProfile from './components/Hubs/HubProfile';
import { RootState } from './store';
import { useSelector } from 'react-redux';

const SentryRoute = Sentry.withSentryRouting(Route);

interface ProtectedRouteProps extends RouteProps {
  component: React.ComponentType<any>;
}

const stateSelector = (state: RootState) => {
  const { uiToggle } = state;
  const { useNewUI } = uiToggle;
  return {
    useNewUI,
  };
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const { useNewUI } = useSelector(stateSelector);

  return (
    <SentryRoute
      {...rest}
      render={(props) =>
        getCookie('access_token') ? (
          // Temporary conditional to render new ui until all components are updated
          !useNewUI ? (
            <>
              <NavBarIndex />
              <Component {...props} />
            </>
          ) : (
            <NavBarIndex>
              <Component {...props} />
            </NavBarIndex>
          )
        ) : (
          <Redirect to="/auth/login" />
        )
      }
    />
  );
};

const App = () => {
  const match_register = useRouteMatch<{ token: string }>('/register/:token');

  const history = useHistory();

  Sentry.init({
    dsn:
      process.env.NODE_ENV === 'development'
        ? ''
        : 'https://207dc18ba022416c8b41fb9b4c2351ee@o926607.ingest.sentry.io/6375017',
    environment: process.env.NODE_ENV,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        tracingOrigins: [restHost, storageHost, webSocketHost],
      }),
    ],
    tracesSampleRate: 1.0,
  });

  if (match_register && match_register.params.token !== 'verify') {
    return <Register match={match_register} />;
  }

  return (
    <BrowserRouter>
      <Switch>
        <SentryRoute exact path="/auth/login" component={Login} />
        <SentryRoute
          exact
          path="/auth/resetPassword"
          component={ResetPassword}
        />
        <SentryRoute
          exact
          path="/register/verify"
          component={RegisterEmailVerify}
        />
        <ProtectedRoute exact path="/" component={DashboardIndex} />
        <ProtectedRoute exact path="/v2/owners" component={HubOwners} />
        <ProtectedRoute
          exact
          path="/v2/owners/:id"
          component={HubOwnerProfile}
        />
        <ProtectedRoute exact path="/v2/caregivers" component={CaregiversNew} />
        <ProtectedRoute
          exact
          path="/v2/caregivers/:id"
          component={CaregiverProfile}
        />
        <ProtectedRoute exact path="/v2/hubs" component={HubIndex} />
        <ProtectedRoute exact path="/v2/hubs/:id" component={HubProfile} />
        <ProtectedRoute path="/caregivers" component={Caregivers} />
        <ProtectedRoute exact path="/customers" component={Customers} />
        <ProtectedRoute
          exact
          path="/customers/create"
          component={CreateNewFacilityUser}
        />
        <ProtectedRoute
          exact
          path="/customers/owner/create"
          component={CreateNewOwner}
        />
        <ProtectedRoute
          exact
          path="/customers/:customer_id"
          component={CustomerDetail}
        />
        <ProtectedRoute
          exact
          path="/customers/:customer_id/medication/:med_event_id/history"
          component={MedicationEventHistory}
        />
        <ProtectedRoute
          exact
          path="/customers/:customer_id/care-team/:care_team_id"
          component={CareTeamDetails}
        />
        <ProtectedRoute
          exact
          path="/customers/:customer_id/create-primary-contact"
          component={CreatePrimaryContact}
        />
        <ProtectedRoute path="/devices" component={Devices} />
        <ProtectedRoute
          path="/device/:deviceSerialNumber"
          component={DeviceDetail}
        />
        <ProtectedRoute exact path="/facility" component={MyFacilityDetail} />
        <ProtectedRoute exact path="/facilities" component={Facilities} />
        <ProtectedRoute
          exact
          path="/facilities/add"
          component={AddNewFacility}
        />
        <ProtectedRoute
          exact
          path="/facilities/:facility_id"
          component={FacilityDetail}
        />
        <ProtectedRoute exact path="/myAccount" component={MyAccount} />
        <ProtectedRoute path="/owners" component={Owners} />
        <ProtectedRoute exact path="/pharmacies" component={Pharmacies} />
        <ProtectedRoute
          exact
          path="/pharmacies/:pharmacy_id"
          component={PharmacyDetail}
        />
        <ProtectedRoute exact path="/reporting" component={Reporting} />
        <ProtectedRoute exact path="/ops" component={Operations} />
        <ProtectedRoute path="/software" component={Software} />
        <ProtectedRoute
          path="/softwareDeployment"
          component={SoftwareDeployment}
        />
        <ProtectedRoute
          path="/softwareVersion/create"
          component={CreateNewSoftwareVersion}
        />
        <ProtectedRoute
          path="/softwareVersion/:versionId"
          component={EditSoftwareVersion}
        />
        <Redirect to="/" />
      </Switch>
      <ToastContainer
        position="bottom-left"
        autoClose={10000}
        hideProgressBar={true}
        closeOnClick
        transition={Slide}
      />
    </BrowserRouter>
  );
};

export default App;
