import React from 'react';
import {
  styled,
  Box,
  Typography,
  Select,
  MenuItem,
  TablePaginationProps,
} from '@mui/material';
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  gridPageSizeSelector,
  gridRowCountSelector,
  GridRowsProp,
  useGridApiContext,
  useGridSelector,
  GridColDef,
} from '@mui/x-data-grid';
import MuiPagination from '@mui/material/Pagination';

// Styled DataGrid
const StyledDataGrid = styled(DataGrid)({
  borderRadius: '16px',
  overflow: 'hidden',
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: '#F6F6F9',
    color: '#2A2A30',
  },
  '& .MuiDataGrid-cell': {
    color: '#2A2A30',
  },
});

function CustomPagination(props: any) {
  const apiRef = useGridApiContext();

  const handlePageChange = (_event: any, newPage: number) => {
    apiRef.current.setPage(newPage);
  };

  return <Pagination {...props} onPageChange={handlePageChange} />;
}

function Pagination({
  className,
}: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) {
  const apiRef = useGridApiContext();

  const handlePageChange = (_event: any, newPage: number) => {
    apiRef.current.setPage(newPage);
  };

  const handlePageSizeChange = (event: any) => {
    const newPageSize = event.target.value;
    apiRef.current.setPageSize(newPageSize); // Updates the page size in the DataGrid
  };

  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
  const rowCount = useGridSelector(apiRef, gridRowCountSelector);
  const currentPage = useGridSelector(apiRef, gridPageSelector);
  const currentPageSize = pageSize ?? 10;

  // Calculate the start and end rows for the current page
  const startRow = currentPage * currentPageSize + 1;
  const endRow = Math.min((currentPage + 1) * currentPageSize, rowCount);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <Box sx={{ marginLeft: '16px' }}>
        {`${startRow}–${endRow} of ${rowCount} results`}
      </Box>

      {/* Custom Pagination  */}
      <Box
        sx={{
          border: '1px solid #D4D4D9',
          borderRadius: '16px',
          padding: '4px',
        }}
      >
        <MuiPagination
          color="secondary"
          className={className}
          count={pageCount}
          page={currentPage + 1}
          onChange={(_, newPage) => {
            handlePageChange(null, newPage - 1); // Pass `null` for the event, as it's not required
          }}
          sx={{
            '& .MuiPaginationItem-root': {
              borderRadius: '8px',
            },
          }}
        />
      </Box>

      {/* Results per page selector on the right */}
      <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '16px' }}>
        <Typography variant="body2" sx={{ marginRight: '8px' }}>
          Results per page:
        </Typography>
        <Select
          value={currentPageSize}
          onChange={handlePageSizeChange}
          variant="outlined"
          sx={{
            borderRadius: '16px',
            paddingX: '10px',
            height: '40px',
            minWidth: '20px',
          }}
          size="small"
        >
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={20}>20</MenuItem>
        </Select>
      </Box>
    </Box>
  );
}

// Reusable DataGridTable Component
interface DataGridTableProps {
  rows: GridRowsProp;
  columns: GridColDef[];
  height?: string;
  maxHeight?: string;
  pageSize?: number;
  loading?: boolean;
}

const DataGridTable: React.FC<DataGridTableProps> = ({
  rows,
  columns,
  height = '100%',
  maxHeight = '64vh',
  pageSize = 10,
  loading = false,
}) => {
  return (
    <Box height={height} maxHeight={maxHeight} py={2}>
      <StyledDataGrid
        rows={rows}
        columns={columns}
        hideFooterSelectedRowCount
        loading={loading}
        slots={{
          pagination: CustomPagination,
        }}
        initialState={{
          pagination: {
            paginationModel: { pageSize, page: 0 },
          },
        }}
      />
    </Box>
  );
};

export default DataGridTable;
