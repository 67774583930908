import React from 'react';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { Box, Button } from '@mui/material';
import SelectFormField from '../../Shared/SelectFormField';
import { FACILITY_ROLE } from '../../../shared/constants';
import NewModal from '../../Shared/NewModal';

const schema = yup.object({
  role: yup.string().required('Role is required'),
});

interface ChangeRoleModalProps {
  onClose: () => void;
  onSubmit: (values: { role: number }) => void;
  initialValues: { role: number };
}

const ChangeRoleModal: React.FC<ChangeRoleModalProps> = ({
  onClose,
  onSubmit,
  initialValues,
}) => {
  const roleData = [
    { label: 'Facility admin', value: FACILITY_ROLE.FACILITY_ADMIN },
    { label: 'Scheduler', value: FACILITY_ROLE.FACILITY_SCHEDULER },
    { label: 'Caregiver', value: FACILITY_ROLE.FACILITY_CAREGIVER },
  ];

  return (
    <NewModal onClose={onClose} title="Change role">
      <Formik
        validationSchema={schema}
        onSubmit={onSubmit}
        initialValues={initialValues}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Box display="flex" flexDirection="column" gap={2}>
              <Field
                label="Role"
                name="role"
                placeholder="Select Role"
                component={SelectFormField}
                options={roleData}
              />
            </Box>
            <Box display="flex" justifyContent="flex-end" mt={2}>
              <Button onClick={onClose} color="secondary">
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                sx={{ ml: 2 }}
              >
                Change Role
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </NewModal>
  );
};

export default ChangeRoleModal;
