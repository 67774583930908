import {
  Box,
  Button,
  Chip,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { lightBackground1, lightBlack } from '../../mainTheme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faBarsFilter,
  faPenToSquare,
  faCheck,
  faTimes,
} from '@fortawesome/pro-light-svg-icons';
import NewModal from '../Shared/NewModal';
import AddNewHubOwner, {
  HubOwnerFormValues,
} from './components/AddNewHubOwner';
import { FormikProps } from 'formik';
import HubOwnersFilterForm from './components/HubOwnersFilterForm';
import { useHistory } from 'react-router-dom';
import DataGridTable from '../Shared/DataGridTable/DataGridTable';
import { useDispatch, useSelector } from 'react-redux';
import {
  addHubOwner,
  fetchHubOwners,
  resetAddHubOwnerSuccess,
} from '../../store/hubOwner/actionCreators';
import { RootState } from '../../store';
import { formatDate } from '../../shared/utils';

const ActionCell = ({ customerId }: { customerId: number }) => {
  const history = useHistory();
  const handleNavigation = () => {
    history.push(`/v2/owners/${customerId}`);
  };

  return (
    <Box display="flex" justifyContent="flex-end" width="100%">
      <IconButton size="small" onClick={handleNavigation}>
        <FontAwesomeIcon
          icon={faPenToSquare}
          style={{ marginRight: 16 }}
          size="sm"
        />
      </IconButton>
    </Box>
  );
};

const columns = [
  { field: 'hubOwner', headerName: 'Hub owner', flex: 1 },
  {
    field: 'medStatus',
    headerName: 'Med status',
    flex: 1,
    renderCell: (params: any) => (
      <Box display="flex" alignItems="center">
        <Chip
          icon={
            <FontAwesomeIcon
              icon={params.value.icon}
              style={{ color: params.value.color }}
            />
          }
          label={
            <Typography sx={{ color: lightBlack }} variant="caption">
              {params.value.text}
            </Typography>
          }
          sx={{
            backgroundColor:
              params.value.text === 'Up to date'
                ? 'var(--Semantics-S2---Success-Bg, #ECF8F0)'
                : `var(--Semantics-S11---Error-Bg, #FCF0EE)`,
            color: 'white',
            '& .MuiChip-icon': {
              color: 'white',
            },
          }}
        />
      </Box>
    ),
  },
  { field: 'nextMedEvent', headerName: 'Next med event', flex: 1 },
  { field: 'facility', headerName: 'Facility', flex: 1 },
  {
    field: 'action',
    headerName: 'Action',
    renderCell: (params: any) => <ActionCell customerId={params.row.id} />,
  },
];

const HubOwners = () => {
  const dispatch = useDispatch();
  const { hubOwners, isLoading, addHubOwnerSuccess } = useSelector(
    (state: RootState) => state.hubOwner
  );

  useEffect(() => {
    dispatch(fetchHubOwners());
  }, [dispatch]);

  const [isAddHubOwnerModalOpen, setIsAddHubOwnerModalOpen] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({
    owner_name: '',
    med_status: '',
    facility: '',
  });

  const rows = hubOwners?.map((owner) => ({
    id: owner.customer_id,
    hubOwner: `${owner.first_name} ${owner.last_name}`,
    medStatus: {
      icon: owner.med_status === 'up to date' ? faCheck : faTimes,
      color: owner.med_status === 'up to date' ? 'green' : 'red',
      text: owner.med_status === 'up to date' ? 'Up to date' : 'Missed',
    },
    nextMedEvent: owner.next_med_event
      ? formatDate(owner.next_med_event)
      : 'No data',
    facility: owner.facility_name || 'No data',
    action: faBarsFilter,
  }));

  const formikRef = useRef<FormikProps<HubOwnerFormValues>>(null);
  const formRef =
    useRef<
      FormikProps<{ owner_name: string; med_status: string; facility: string }>
    >(null);

  const handleContinue = () => {
    if (formikRef.current) {
      formikRef.current.submitForm();
    }
    if (formRef.current) {
      formRef.current.submitForm();
    }
  };

  const onSubmit = (values: any) => {
    if (formikRef.current) {
      dispatch(addHubOwner(values));
    }
  };

  useEffect(() => {
    if (addHubOwnerSuccess) {
      setIsAddHubOwnerModalOpen(false);
      dispatch(resetAddHubOwnerSuccess());
      dispatch(fetchHubOwners());
    }
  }, [addHubOwnerSuccess, dispatch]);

  const onSubmitFilterForm = (values: any) => {
    if (formRef.current) {
      setFilters(values);
      setIsFilterModalOpen(false);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  // Filter data based on search term

  const filteredData = rows?.filter((row) => {
    const matchesSearchTerm =
      row.hubOwner.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.medStatus.text.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.nextMedEvent.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.facility.toLowerCase().includes(searchTerm.toLowerCase());

    const matchesFilters =
      (filters.owner_name === '' ||
        row.hubOwner
          .toLowerCase()
          .includes(filters.owner_name.toLowerCase())) &&
      (filters.facility === '' ||
        row.facility.toLowerCase().includes(filters.facility.toLowerCase())) &&
      (filters.med_status === '' || row.medStatus.text === filters.med_status);

    return matchesSearchTerm && matchesFilters;
  });

  return (
    <Box display="flex" flexDirection="column" bgcolor={lightBackground1}>
      <Box flexGrow={1} p={4}>
        <Box display="flex" justifyContent="space-between">
          <Typography>Hub owners</Typography>
          <Button
            type="submit"
            variant="outlined"
            color="secondary"
            startIcon={<FontAwesomeIcon icon={faPlus} />}
            onClick={() => setIsAddHubOwnerModalOpen(true)}
          >
            Add new Hub Owner
          </Button>
        </Box>
        <Typography variant="caption">Search</Typography>
        <Box display="flex" gap={2}>
          <TextField
            placeholder="Search"
            value={searchTerm}
            onChange={handleSearchChange}
            fullWidth
            variant="outlined"
          />
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<FontAwesomeIcon icon={faBarsFilter} />}
            onClick={() => setIsFilterModalOpen(true)}
          />
        </Box>
        <DataGridTable
          rows={filteredData || []}
          columns={columns}
          loading={isLoading}
        />
      </Box>
      {isAddHubOwnerModalOpen ? (
        <NewModal
          showCloseX
          title="Add new Hub Owner"
          onClose={() => {
            setIsAddHubOwnerModalOpen(false);
          }}
          actions={{
            primaryAction: { onClick: handleContinue },
          }}
          backdropBlur
          isLoading={isLoading}
        >
          <AddNewHubOwner formRef={formikRef} onSubmit={onSubmit} />
        </NewModal>
      ) : null}
      {isFilterModalOpen ? (
        <NewModal
          showCloseX
          title="Filter"
          onClose={() => {
            setIsFilterModalOpen(false);
            setFilters({
              owner_name: '',
              med_status: '',
              facility: '',
            });
            if (formRef.current) {
              formRef.current.resetForm();
            }
          }}
          actions={{
            primaryAction: { onClick: handleContinue },
          }}
        >
          <HubOwnersFilterForm
            formRef={formRef}
            onSubmit={onSubmitFilterForm}
            initialValues={filters}
          />
        </NewModal>
      ) : null}
    </Box>
  );
};

export default HubOwners;
