import React from 'react';
import Dashboard from './Dashboard';
import UIToggleWrapper from '../../shared/UIToggleWrapper/UIToggleWrapper';
import { Box } from '@mui/material';

const DashboardIndex = UIToggleWrapper(Dashboard, () => (
  <Box display="flex" justifyContent="center">
    New Home Screen
  </Box>
));

export default DashboardIndex;
