import React, { useState } from 'react';
import { Box, Button, Typography, TextField } from '@mui/material';
import { ReactComponent as ResetPasswordIcon } from '../../../images/icons/reset_password_icon.svg';

interface MfaVerificationProps {
  verifyMfaCode: (mfaCode: string) => void;
  resendCode: () => void;
}

const MfaVerification: React.FC<MfaVerificationProps> = ({
  verifyMfaCode,
  resendCode,
}) => {
  const [mfaCode, setMfaCode] = useState('');

  return (
    <Box mt={4} display="flex" flexDirection="column" gap={2}>
      <ResetPasswordIcon />
      <Typography>Multi-Factor Authentication</Typography>
      <Typography variant="subtitle2">
        A verification code has been sent to your email. Enter the code to
        continue.
      </Typography>
      <TextField
        label=""
        placeholder="Enter verification code"
        value={mfaCode}
        onChange={(e) => setMfaCode(e.target.value)}
        fullWidth
        margin="normal"
      />
      <Button
        variant="contained"
        color="secondary"
        onClick={() => verifyMfaCode(mfaCode)}
      >
        Verify
      </Button>
      <Button variant="text" color="secondary" onClick={resendCode}>
        Resend code
      </Button>
    </Box>
  );
};

export default MfaVerification;
