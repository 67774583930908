import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useMemo } from 'react';
import * as yup from 'yup';
import { TextInputFormField } from '../../Shared/TextInputFormField';
import SelectFormField from '../../Shared/SelectFormField';
import { Box } from '@mui/material';
import { FACILITY_ROLE } from '../../../shared/constants';

const schema = yup.object({
  owner_name: yup.string().trim(),
  med_status: yup.string(),
});

interface CaregiverFilterFormProps {
  formRef: React.RefObject<FormikProps<{ caregiver: string; role: string }>>;
  onSubmit: (values: any) => void;
  initialValues: { caregiver: string; role: string };
}

const CaregiverFilterForm: React.FC<CaregiverFilterFormProps> = ({
  formRef,
  onSubmit,
  initialValues,
}) => {
  const roleData = useMemo(() => {
    return [
      { label: 'Facility admin', value: FACILITY_ROLE.FACILITY_ADMIN },
      { label: 'Scheduler', value: FACILITY_ROLE.FACILITY_SCHEDULER },
      {
        label: 'Caregiver',
        value: FACILITY_ROLE.FACILITY_CAREGIVER,
      },
    ];
  }, []);

  return (
    <Box>
      <Formik
        innerRef={formRef}
        validationSchema={schema}
        onSubmit={onSubmit}
        initialValues={initialValues}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Box display="flex" flexDirection="column" gap={2}>
              <Field
                label="Caregiver name"
                name="caregiver"
                placeholder="First or Last name"
                component={TextInputFormField}
              />
              <Field
                label="Role"
                name="role"
                placeholder="Select Role"
                component={SelectFormField}
                options={roleData}
              />
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default CaregiverFilterForm;
